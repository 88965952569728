<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('laundry_services.laundry_service') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label for="name">{{ $t('laundry_services.name') }}<span class="text-danger">*</span></label>
                <input id="name" v-model="data.name" :class="validation && validation.name ? 'is-invalid' : ''"
                       :placeholder="$t('laundry_services.name')" class="form-control"
                       type="text">
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.name[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label for="name">{{ $t('laundry_services.symbol') }}</label>
                <input id="name" v-model="data.symbol" :class="validation && validation.symbol ? 'is-invalid' : ''"
                       :placeholder="$t('laundry_services.symbol')" class="form-control"
                       type="text">
                <span v-if="validation && validation.symbol" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.symbol[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label>{{ $t('laundry_services.order') }}<span class="text-danger">*</span></label>
                <select v-model="data.service_order" :class="validation && validation.service_order ? 'is-invalid' : ''"
                        class="custom-select"
                        type="text">
                  <option v-for="(row, index) in service_order_count" :key="'order'+index">{{ row }}</option>
                </select>
                <span v-if="validation && validation.service_order"
                      class="fv-plugins-message-container invalid-feedback">
                  {{ validation.service_order[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label for="tax">{{ $t('laundry_services.tax') }}</label>
                <multiselect v-model="tax"
                             :class="validation && validation.name ? 'is-invalid' : ''"
                             :multiple="false"
                             :options="taxes"
                             :placeholder="$t('laundry_services.tax')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="selectedTax"
                             @search-change="getTaxes($event)">
                </multiselect>
                <span v-if="validation && validation.tax_id" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.tax_id[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <label for="notes">{{ $t('laundry_services.notes') }}</label>
                <textarea id="notes" v-model="data.notes" class="form-control" type="text"></textarea>
                <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.notes[0] }}
                </span>
              </div>
              <div class="col-lg-12 mb-5">
                <b-form-checkbox v-model="data.is_active" name="check-button" size="lg" switch>{{
                    $t('status')
                  }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-md-12">
          <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803" style="height: 45px;">
            <h6 class="my-auto text-white">{{ $t('routing_management.operations') }}</h6>
            <button class="btn btn-primary btn-sm" type="button" @click="addItemRowToList">{{ $t('add_more') }}</button>
          </div>
        </div>
        <div class="col-md-12">
          <div class="table-responsive bg-white">
            <table class="table table-row-bordered w-100" @keyup.alt.enter="addItemRowToList"
                   @keyup.alt.46="removeItemRowFromList(0)">
              <thead>
              <tr>
                <th>{{ $t('users.name') }}</th>
                <th>{{ $t('min_price') }}</th>
                <th style="width: 300px;">{{ $t('category') }}</th>
                <th> {{ $t('roles.description') }}</th>
                <th> {{ $t('roles.status') }}</th>
                <th> {{ $t('customers.is_default') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in operations" :key="index">
                <td>
                  <input v-model="row.name"
                         :class="validation && validation['operations.'+index+'.name'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="text"/>
                  <span v-if="validation && validation['operations.'+index+'.name']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['operations.' + index + '.name'][0] }}
                  </span>
                </td>
                <td>
                  <input v-model="row.min_price"
                         :class="validation && validation['operations.'+index+'.min_price'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="number"/>
                  <span v-if="validation && validation['operations.'+index+'.min_price']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['operations.' + index + '.min_price'][0] }}
                  </span>
                </td>
                <td>
                  <multiselect
                      v-model="row.selectedCategory"
                      :key="'categories'+ index"
                      :multiple="true"
                      :options="categories"
                      :placeholder="$t('category')"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      :taggable="false"
                      class="w-100"
                      label="label"
                      track-by="id"
                      @input="selectedCategories(index)">
                  </multiselect>
                </td>
                <td>
                  <input v-model="row.description"
                         :class="validation && validation['operations.'+index+'.description'] ? 'is-invalid' : ''"
                         class="form-control"
                         type="text"/>
                  <span v-if="validation && validation['operations.'+index+'.description']"
                        class="fv-plugins-message-container invalid-feedback">
                    {{ validation['operations.' + index + '.description'][0] }}
                  </span>
                </td>
                <td>
                  <b-form-checkbox v-model="row.is_active" name="check-button" size="lg" switch>
                  </b-form-checkbox>
                </td>
                <td>
                  <b-form-checkbox v-model="row.is_default" name="check-button"
                                   size="lg" switch @change="changeDefaultStatus(index, row.is_default)">
                  </b-form-checkbox>
                </td>
                <td>
                  <v-icon v-if="operations.length > 1" small style="color: #dc3545;"
                          @click="removeItemRowFromList(index)">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-5">
            <button class="btn btn-primary mr-2" type="button" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-journals-setting",
  data() {
    return {
      mainRoute: 'settings/laundry_services',
      mainRouteDependency: 'base/dependency',
      idEdit: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,
      service_order_count: 20,
      taxes: [],
      tax: null,
      operations: [],
      categories: [],
      data: {
        id: null,
        name: null,
        symbol: null,
        notes: null,
        tax_id: null,
        service_order: null,
        is_active: true,
      },
      validation: null,
      operations_form: {
        name: null,
        min_price: null,
        selectedCategory: [],
        category: [],
        is_active: true,
        is_default: false,
        description: null
      },
    };
  },

  watch: {},
  methods: {
    save() {
      let $url = this.idEdit ? this.mainRoute + '/' + this.idEdit : this.mainRoute;
      let $method = this.isEditing ? 'put' : 'post';
      ApiService[$method]($url, {
        ...this.data,
        operations: this.operations,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/settings/laundry-services');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    getData() {
      ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
        this.isEditing = true;
        let $data = response.data.data;
        this.data = {
          id: $data.id,
          name: $data.name,
          symbol: $data.symbol,
          notes: $data.notes,
          tax_id: $data.tax_id,
          service_order: $data.service_order,
          is_active: $data.is_active,
        };
        if (response.data.data.operations) {
          this.operations = response.data.data.operations;
          this.categories.length > 0 && this.setSelectedCategoryAfterLoadDate(this.operations);
        } else {
          this.addItemRowToList();
        }
      });
    },
    changeDefaultStatus(index, status) {
      this.operations = this.operations.map((row) => {
        row.is_default = false;
        return row;
      });
      this.operations[index].is_default = status;
    },
    selectedCategories(idx) {
      this.operations[idx].category = this.operations[idx].selectedCategory.map((row) => {
        return row.id;
      });
    },
    async getCategories() {
      await ApiService.get(`${this.mainRouteDependency}/categories?type=1`).then((response) => {
        this.categories = response.data.data;
      })
    },
    setSelectedCategoryAfterLoadDate(operations) {
      operations.map((row, index) => {
        this.operations[index].selectedCategory = this.categories.filter((category) => {
          return row.category.includes(category.id);
        });
      });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },
    selectedTax(event) {
      this.data.tax_id = event.id;
    },
    addItemRowToList() {
      this.operations.unshift(this.operations_form);
      this.operations_form = {
        name: null,
        min_price: null,
        selectedCategory: [],
        category: [],
        is_active: true,
        is_default: false,
        description: null
      };
    },
    removeItemRowFromList(index) {
      if (this.operations.length > 1) {
        this.operations.splice(index, 1);
      }
    },
  },

  computed: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.laundry_services"),
      route: '/settings/laundry-services'
    }, {
      title: this.idEdit ? this.$t('update') : this.$t('create') + ' ' + this.$t("laundry_services.laundry_service"),
    }]);

    if (this.idEdit) {
      this.getData();
    } else {
      this.addItemRowToList();
    }
  },
  created() {
    this.getCategories();
  }
};
</script>


